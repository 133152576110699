.core-list .MuiListItem-root {
  /* box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
  background: #fff;
  margin-bottom: 4px;
  align-self: flex-start;
  cursor: pointer;
  padding: 12px;
  flex-wrap: wrap;
}


html .b-b,
body .b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}
.image-width {
  p {
    img {
      width: 100%;
    }
  }
}

.circled-number {
  color: #fff0f0;
  // border: 2px solid #666;
  border-radius: 50%;
  font-size: 0.75rem;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 1.5em;
  height: 1.5em;
  background: orange;
  padding: 2px 5px 2px 4px;
}

.circled-number-blank {
  color: black;
  // border: 2px solid #666;
  border-radius: 50%;
  font-size: 0.75rem;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 1.5em;
  height: 1.5em;
  background: transparent;
}

.chip-style {
  height: 15px;
  font-size: 10px;
  background: rgb(241, 222, 184);
  border-radius: 4.5 px;
  margin-top: 3 px;
  color: orange;
}
.search-header-booking {
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  &.MuiList-root {
    padding: 0px;
    width: 70%;
  }
  .MuiListItemIcon-root {
    min-width: 36px;
  }
  .MuiListItem-root {
    padding-left: 12px;
  }
  .MuiListItemText-root {
    margin: 0px;
    display: flex;
    padding: 4px 0px;
  }
  .MuiIconButton-edgeEnd {
    padding: 8px;
    color: #212121;
    margin-right: -8px;
  }
  .MuiInputBase-root {
    width: 100%;

    .MuiInputBase-input {
      padding: 0px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.search-filter-booking {
  display: flex;
  margin-bottom: 4px;
  .search-header-booking {
    width: 70%;
  }
  .filter-dropdown {
    flex: 1;
    padding-left: 6px;

    .search-header-booking {
      width: 100%;
    }
  }
}
.search-filter-tab {
  position: fixed !important;
  z-index: 100;
  top: 67px;
  width: 100%;
  display: flex;
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    top: 157px;
  }
}
.listItem-fiter-tab {
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  &.MuiList-root {
    padding: 0px;
  }
  .MuiListItemIcon-root {
    min-width: 36px;
  }
  .MuiListItem-root {
    padding-left: 12px;
  }
  .MuiListItemText-root {
    margin: 0px;
    display: flex;
    padding: 4px 0px;
  }
  .MuiIconButton-edgeEnd {
    padding: 8px;
    color: #212121;
    margin-right: -8px;
  }
  .MuiInputBase-root {
    width: 100%;

    .MuiInputBase-input {
      padding: 0px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.banner-section {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  //   display: flex;
  //   box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;

  background-color: #ffffff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  height: 70px;
  text-align: center;
  margin: auto;
  padding: 10px 0px;
  img {
    display: block;
    margin: 0 auto;
    width: 80px;
  }

  .button-section {
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    .MuiButton-root {
      min-width: 126px;
    }
    @media screen and (min-width: 600px) {
      justify-content: flex-end !important;
      
    }
  }
}
.image-width {
  p {
    img {
      width: 100%;
    }
  }
}
.display-linebreak {
  white-space: pre-line;
}
