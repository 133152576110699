.core-tr td.table-row-sticky.MuiTableCell-root.MuiTableCell-body {
  position: sticky;
  width: 6em;
  left: 0;
  -webkit-box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
  -moz-box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
  box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
  background: rgb(255, 255, 255);
  //   -webkit-box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
  //   -moz-box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
  //   box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
}

.core-tr td.header-bg.MuiTableCell-root.MuiTableCell-body {
  background-color: white;
  color: black;
  font-weight: 500;
}

.core-tr th.MuiTableCell-root.MuiTableCell-body {
  position: absolute;
  width: 6em;
  -webkit-box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
  -moz-box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
  box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);

  background-color: #ffffff;
}

.core-table tr:nth-child(odd) {
  background-color: white;
}

.core-table tr:nth-child(even) {
  background-color: #ffffff;
}

.core-tr th:nth-child(even) {
  background-color: white;
}

td.MuiTableCell-root {
  border-bottom: 0.5px solid gray;
}
