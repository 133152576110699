.individual-calendar div span.MuiPickersCalendarHeader-dayLabel {
  // color: #212121;
  //   margin: 0 8.5px;
  margin: 4px;
  width: 40px;
}

.individual-calendar {
  background-color: #fff;
  // margin: 8px 6px;
  border-radius: 3px;
  width: 100%;
  // height: 700px;
  min-width: 0;
  margin-top: 4px;
  // box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
  //   0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
}

.individual-calendar
  div
  p.MuiTypography-root.MuiTypography-body1.MuiTypography-alignCenter {
  font-weight: 600;
  color: #212121;
  font-size: 14px;
}

.individual-calendar div .MuiPickersCalendarHeader-iconButton {
  z-index: 1;
  background-color: transparent;
  color: #212121;
}

.individual-calendar
  button
  span
  p.MuiTypography-root.MuiTypography-body2.MuiTypography-colorInherit {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.individual-calendar div button.MuiPickersDay-day {
  color: #ffffff;
  margin: 3px;
  width: 40px;
  // background: #c3c3c3;
  //   margin: 0 8.5px;
  //   margin: 3px 6.5px;
}
// current date
.individual-calendar div button.MuiPickersDay-daySelected {
  color: #212121;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0);
}

.individual-calendar div.MuiPickersCalendarHeader-switchHeader {
  //   padding-top: 20px;
  margin-top: 0px;
  // width: 55%;
}

.day-picker {
  .MuiIconButton-root {
    border-radius: 3px;
    height: 30px;
    padding: 8px;
    font-size: 0.75rem;
    margin: 0px 8.5px;
  }
}
.color-orange {
  .MuiIconButton-root {
    background: #e64a19 !important;
  }
}
.color-light-orange {
  .MuiIconButton-root {
    background: #ffb11f !important;
  }
}
.color-blue {
  .MuiIconButton-root {
    background: #5367ff !important;
  }
}
.color-dark-blue {
  .MuiIconButton-root {
    background: #001088 !important;
  }
}

.color-season {
  width: 20px;
  height: 18px;
  border-radius: 4px;
  margin-right: 10px;
}

.day-type-ph {
  width: 0px;
  height: 0px;
  border-bottom: 18px solid transparent;
  position: absolute;
  border-left: 18px solid #00bf0f;
  z-index: 1;
  margin: 3px;
  margin-top: -6px;
  margin-left: -14px;
}

.day-type-rd {
  width: 0px;
  height: 0px;
  border-bottom: 18px solid transparent;
  position: absolute;
  border-left: 18px solid #04a2b5;
  z-index: 1;
  margin: 3px;
  margin-top: -6px;
  margin-left: -14px;
}
.day-type-od {
  width: 0px;
  height: 0px;
  border-bottom: 18px solid transparent;
  position: absolute;
  border-left: 18px solid #ff36e5;
  z-index: 1;
  margin: 3px;
  margin-top: -6px;
  margin-left: -14px;
}
.day-type-leave-timeoff-overtime {
  width: 0px;
  height: 0px;
  border-top: 18px solid transparent;
  position: absolute;
  border-right: 18px solid black;
  z-index: 1;
  margin: 3px;
  margin-bottom: -6px;
  margin-right: -14px;
}
.day-type-bl {
  width: 0px;
  height: 0px;
  border-bottom: 18px solid transparent;
  position: absolute;
  border-left: 18px solid #ff0000;
  z-index: 1;
  margin: 3px;
  margin-top: -6px;
  margin-left: -14px;
}

// .color-green {
//     .MuiIconButton-root {
//       background: #00bf0f !important;
//     }
//   }
//   .color-light-blue {
//     .MuiIconButton-root {
//       background: #04a2b5 !important;
//     }
//   }
//   .color-pink {
//     .MuiIconButton-root {
//       background: #ff36e5 !important;
//     }
//   }

//! group calendar

.group-calendar {
  .legend {
    justify-content: flex-end;
  }

  .calendar-month {
    .MuiIconButton-root {
      padding: 0px;
    }
    .MuiSvgIcon-root {
      color: #212121;
    }
  }
}
.calendar-days {
  text-align: center;
}
.calendar-arrow.MuiIconButton-root {
  padding: 0px;
  margin-top: 12px;
  .MuiSvgIcon-root {
    color: #212121;
  }
}
.calendar-divider {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 4px;
}

.assign-button {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  background: #c3c3c3;
  cursor: pointer;
}
.employee-avatar.MuiAvatar-root {
  width: 30px;
  height: 30px;
}
.group-daytype-ph {
  width: 0px;
  height: 0px;
  border-bottom: 18px solid transparent;
  position: absolute;
  border-left: 18px solid #00bf0f;
  z-index: 1;
}
.group-daytype-rd {
  width: 0px;
  height: 0px;
  border-bottom: 18px solid transparent;
  position: absolute;
  border-left: 18px solid #04a2b5;
  z-index: 1;
}
.group-daytype-od {
  width: 0px;
  height: 0px;
  border-bottom: 18px solid transparent;
  position: absolute;
  border-left: 18px solid #ff36e5;
  z-index: 1;
}

#demo {
  background: #000;
  width: 200px;
  height: 200px;
}

.summary-button {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background: #c3c3c3;
  cursor: pointer;
}
.last-row {
  // background: #e3e3e3;
  width: 100%;
  // padding: 8px 6px;
  color: #4a5bdc;
  // box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
  //   0 4px 5px 2px rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.4);
}

.assign-day-picker.MuiIconButton-root {
  font-size: 0.75rem;
  width: 40px;
  height: 30px;
  margin: 3px;
  padding: 8px;
  border-radius: 3px;
  background: #c4c4c4;
}
