.table {
  min-width: 950px;
}

.arrBackgroudColor {
  // background: #fff5e7;
  border-right: 0.5px solid white;
}

.external-subheader-table {
  margin-top: 56px;
  @media screen and (min-width: 600px) {
    margin-top: 39px !important;
  }
}

.summaryTable {
  width: 100%;
  @media screen and (min-width: 600px) {
    box-shadow: none;
    width: calc(100% - 288px);
    right: 24px;
  }
}

.externalSummaryTable {
  width: 100%;
  left: 0;
}
// .tableFixHead {
//         overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
//         height: 200px; /* gives an initial height of 200px to the table */
//       }
//       .tableFixHead thead th {
//         position: sticky; /* make the table heads sticky */
//         top: 0px; /* table head will be placed from the top of the table and sticks to it */
//       }
//       table {
//         border-collapse: collapse; /* make the table borders collapse to each other */
//         width: 100%;
//       }
//       th,
//       td {
//         padding: 8px 16px;
//         border: 1px solid #ccc;
//       }
//       th {
//         background: #eee;
//       }
