main {
  width: calc(100% - 300px);
}

html {
  background: #f5f5f5;
}

html,
body {
  min-height: 100%;
  .p-0 {
    padding: 0px;
  }
  .p-10 {
    padding: 10px;
  }
  .p-20 {
    padding: 20px;
  }
  .p-30 {
    padding: 30px;
  }
  .p-l-0 {
    padding-left: 0px;
  }
  .p-l-10 {
    padding-left: 10px;
  }
  .p-l-20 {
    padding-left: 20px;
  }
  .p-r-0 {
    padding-right: 0px;
  }
  .p-r-10 {
    padding-right: 10px;
  }
  .p-r-20 {
    padding-right: 20px;
  }
  .p-r-30 {
    padding-right: 30px;
  }
  .p-r-40 {
    padding-right: 40px;
  }
  .p-t-0 {
    padding-top: 0px;
  }
  .p-t-10 {
    padding-top: 10px;
  }
  html body .p-t-20 {
    padding-top: 20px;
  }
  .p-t-30 {
    padding-top: 30px;
  }
  .p-b-0 {
    padding-bottom: 0px;
  }
  .p-b-5 {
    padding-bottom: 5px;
  }
  .p-b-10 {
    padding-bottom: 10px;
  }
  .p-b-20 {
    padding-bottom: 20px;
  }
  .p-b-30 {
    padding-bottom: 30px;
  }
  .p-b-40 {
    padding-bottom: 40px;
  }
  .m-0 {
    margin: 0px;
  }
  .m-l-5 {
    margin-left: 5px;
  }
  .m-l-10 {
    margin-left: 10px;
  }
  .m-l-15 {
    margin-left: 15px;
  }
  .m-l-20 {
    margin-left: 20px;
  }
  .m-l-30 {
    margin-left: 30px;
  }
  .m-l-40 {
    margin-left: 40px;
  }
  .m-r-5 {
    margin-right: 5px;
  }
  .m-r-10 {
    margin-right: 10px;
  }
  .m-r-15 {
    margin-right: 15px;
  }
  .m-r-20 {
    margin-right: 20px;
  }
  .m-r-30 {
    margin-right: 30px;
  }
  .m-r-40 {
    margin-right: 40px;
  }
  .m-t-0 {
    margin-top: 0px;
  }
  .m-t-2 {
    margin-top: 2px;
  }
  .m-t-5 {
    margin-top: 5px;
  }
  .m-t-10 {
    margin-top: 10px;
  }
  .m-t-15 {
    margin-top: 15px;
  }
  .m-t-20 {
    margin-top: 20px;
  }
  .m-t-30 {
    margin-top: 30px;
  }
  .m-t-40 {
    margin-top: 40px;
  }
  .m-b-0 {
    margin-bottom: 0px;
  }
  .m-b-5 {
    margin-bottom: 5px;
  }
  .m-b-10 {
    margin-bottom: 10px;
  }
  .m-b-15 {
    margin-bottom: 15px;
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
  .m-b-30 {
    margin-bottom: 30px;
  }
  .m-b-40 {
    margin-bottom: 40px;
  }
  .vt {
    vertical-align: top;
  }
  .vm {
    vertical-align: middle;
  }
  .vb {
    vertical-align: bottom;
  }
  .b-r {
    border-right: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-l {
    border-left: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-b {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-t {
    border-top: 1px solid rgba(120, 130, 140, 0.13);
  }
  .b-all {
    border: 1px solid rgba(120, 130, 140, 0.13);
  }
  .overflow-hidden {
    overflow: hidden;
  }
}

.snackbar .MuiSnackbarContent-root {
  color: rgba(255, 255, 255, 0.87);
  background-color: rgb(50, 50, 50);
  font-weight: 500;
}

@media (min-width: 600px) {
  .MuiSnackbar-anchorOriginBottomCenter.snackbar {
    left: 60%;
  }
  .MuiSnackbar-anchorOriginBottomLeft.snackbar {
    left: 26%;
  }
}

.flex-space {
  flex: 1;
}
.flex-space2 {
  flex: 8;
}
.flex-space3 {
  flex: 20;
}

.content-wrapper {
  padding: 8px 6px;
  @media screen and (min-width: 600px) {
    margin-top: 64px;
  }

  &.with-tabSearch {
    padding: 0px 0px;
    margin-top: 90px;
    @media screen and (min-width: 600px) {
      margin-top: 155px;
    }
  }
  &.with-footer {
    padding-bottom: 82px;
  }
  &.with-tabsLHDN {
    padding: 0px 0px;
    margin-top: 90px;
    @media screen and (min-width: 600px) {
      margin-top: 180px;
    }
  }
  &.with-LeavePolicy {
    padding: 0px 0px;
    margin-top: 50px;
    @media screen and (min-width: 600px) {
      margin-top: 120px;
    }
  }
  &.fixed {
    position: fixed;
    z-index: 20;
    top: 69px;
    padding: 0px 0px;
    left: 2px;
    width: 100%;
    @media screen and (min-width: 600px) {
      top: 92px;
      padding: 0px 0px;
      left: 265px;
      box-sizing: border-box;
      width: calc(100% - 288px);
    }
  }
  &.with-tabPolicy {
    padding: 0px 0px;
    margin-top: 130px;
    @media screen and (min-width: 600px) {
      margin-top: 120px;
    }
  }
  &.with-tabsLHDNi {
    margin-top: 32px;
    @media screen and (min-width: 600px) {
      margin-top: 97px !important;
    }
  }
  &.with-footer-n-float {
    padding-bottom: 120px;
  }
  &.with-float {
    padding-bottom: 78px;
  }
  &.with-tabs {
    padding: 0px 0px;
    margin-top: 4px;
    @media screen and (min-width: 600px) {
      margin-top: 90px;
    }
  }
  &.with-company-title-search {
    padding: 0px 0px;
    margin-top: 100px;
    @media screen and (min-width: 600px) {
      margin-top: 90px;
    }
  }

  &.with-date {
    margin-top: 40px;
    @media screen and (min-width: 600px) {
      margin-top: 110px;
    }
  }
  // &.with-float-amt {
  //   padding-bottom: 100px;
  // }
  &.with-tab {
    margin-top: 90px;
    @media screen and (min-width: 600px) {
      // padding-top: 98px;
      margin-top: 155px !important;
    }
  }

  &.with-year-tab {
    margin-top: 145px;
    @media screen and (min-width: 600px) {
      // padding-top: 98px;
      margin-top: 215px !important;
    }
  }
  &.with-topSearch {
    margin-top: 70px;
    @media screen and (min-width: 600px) {
      // padding-top: 98px;
      margin-top: 100px !important;
    }
  }
  &.with-search {
    margin-top: 32px;
    @media screen and (min-width: 600px) {
      margin-top: 99px !important;
    }
  }
  &.with-search2 {
    margin-top: 32px;
    @media screen and (min-width: 600px) {
      margin-top: 92px !important;
    }
  }
  &.with-bulletin {
    margin-top: 28px;
    @media screen and (min-width: 600px) {
      margin-top: 28px !important;
    }
  }
  &.multiple-dynamicInfo {
    margin-top: 60px;
    @media screen and (min-width: 600px) {
      margin-top: 130px !important;
    }
  }
  &.single-dynamicInfo {
    margin-top: 38px;
    @media screen and (min-width: 600px) {
      margin-top: 105px !important;
    }
  }
  &.single-subHeader-searchHeader {
    margin-top: 75px;
    @media screen and (min-width: 600px) {
      margin-top: 146px !important;
    }
  }
  &.attendance-tabs-table {
    margin-top: 6px;
    @media screen and (min-width: 600px) {
      margin-top: 60px !important;
    }
  }
  &.single-subHeader-searchHeader2 {
    margin-top: 65px;
    @media screen and (min-width: 600px) {
      margin-top: 136px !important;
    }
  }
  &.period-month {
    margin-top: 56px;
    @media screen and (min-width: 600px) {
      margin-top: 126px !important;
    }
  }
  &.double-subHeader-searchHeader {
    margin-top: 90px;
    @media screen and (min-width: 600px) {
      margin-top: 160px !important;
    }
  }
  &.attendance-tabs {
    margin-top: 120px;
    @media screen and (min-width: 600px) {
      margin-top: 185px !important;
    }
  }
  &.dynamicinfo-search {
    margin-top: 76px;
    @media screen and (min-width: 600px) {
      margin-top: 146px !important;
    }
  }
  &.multi-dynamicinfo-search {
    margin-top: 103px;
    @media screen and (min-width: 600px) {
      margin-top: 172px !important;
    }
  }
  &.profile-page {
    margin-top: 127px;
    @media screen and (min-width: 600px) {
      margin-top: 195px !important;
    }
  }
  &.search-header-margin-content {
    margin-top: 103px;
    @media screen and (min-width: 600px) {
      margin-top: 170px !important;
    }
  }
  &.multi-dynamicinfo-full-year-header {
    margin-top: 115px;
    @media screen and (min-width: 600px) {
      margin-top: 185px !important;
    }
  }
  &.with-autocomplete-company {
    margin-top: 110px;
    @media screen and (min-width: 600px) {
      margin-top: 130px !important;
    }
  }
  &.multi-select-dynamicinfo {
    margin-top: 83px;
    @media screen and (min-width: 600px) {
      margin-top: 153px !important;
    }
  }
  &.multi-dynamicInfo-date-search-select {
    margin-top: 35px;
    @media screen and (min-width: 600px) {
      margin-top: 38px !important;
    }
  }
  &.single-dynamicInfo-tab-form {
    margin-top: 35px;
    @media screen and (min-width: 600px) {
      margin-top: 106px !important;
    }
  }
  &.reporting-list {
    margin-top: 90px;
  }
  &.multi-dynamicInfo-tab-search {
    margin-top: 148px;
    @media screen and (min-width: 600px) {
      margin-top: 218px !important;
    }
  }
  &.external-basic-header {
    margin-top: 10px;
    @media screen and (min-width: 600px) {
      margin-top: 6px !important;
    }
  }
  &.external-multi-dynamicInfo {
    margin-top: 18px;
    @media screen and (min-width: 600px) {
      margin-top: 0 !important;
    }
  }
}

.empty-listing {
  text-align: center;
  padding: 50px 12px;
  img {
    margin-bottom: 12px;
  }
  .smTitle {
    margin-bottom: 6px;
    white-space: unset;
  }
  .desc {
    white-space: unset;
  }
}

.empty-listing2 {
  text-align: center;
  padding: 5px 12px;
  img {
    margin-bottom: 12px;
  }
  .smTitle {
    margin-bottom: 6px;
    white-space: unset;
  }
  .desc {
    white-space: unset;
  }
}

.remove-padding {
  padding: 0px !important;
  box-shadow: none !important;
}

.MuiButtonBase-root.Mui-disabled {
  background: #e8e8e8;
}

.menu-list {
  .MuiMenuItem-root {
    font-size: 14px;
  }
}

.fixed-search-header {
  position: fixed !important;
  width: 100%;
  z-index: 50;
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    top: 155px;
  }
  &.with-dynamic {
    top: 106px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 194px;
    }
  }
  &.with-month-salary {
    top: 112px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 207px;
    }
  }
  &.with-dynamic-header {
    top: 96px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 190px;
    }
  }
  &.with-dynamic-header {
    top: 96px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 190px;
    }
  }
  &.with-dynamic-multiInfo {
    top: 123px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 213px;
    }
  }
  &.with-dynamic-multiSubInfo {
    top: 138px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 228px;
    }
  }
  &.search-header-margin {
    top: 124px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 225px;
    }
  }
  &.with-tabs {
    top: 115px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 202px;
    }
  }
  &.with-company-title-search {
    top: 97px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 190px;
    }
  }
  &.with-disable-scroll-lock {
    overflow: hidden;
  }
  &.with-tabs-date {
    top: 114px;
    right: 4px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      right: 24px;
      // position: fixed !important;
      // z-index: 100;
      top: 202px;
    }
  }
  &.with-dynamic-tabs-search {
    top: 158px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 238px;
    }
  }
  &.with-tabs-search {
    top: 152px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 240px;
    }
  }
  &.with-tab-reporting-form {
    top: 224px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      top: 320px;
    }
  }
  &.with-tab-overtime-listing {
    top: 290px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      top: 376px;
    }
  }
  &.bulletin-search {
    top: 148px;

    @media screen and (min-width: 600px) {
      width: calc(100% - 298px);
      // position: fixed !important;
      // z-index: 100;
      top: 236px;
    }
  }
  &.with-title-dynamic {
    top: 90px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 184px;
    }
  }
  &.with-title-name-position {
    top: 120px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 214px;
    }
  }
  &.with-dynamic-search {
    top: 128px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 218px;
    }
  }
  &.with-dynamic-year-tabs-search {
    top: 178px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 265px;
    }
  }

  &.with-dynamic-year-tabs {
    top: 176px;
    z-index: 60;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      height: 2.5rem;
      align-content: center;
      // position: fixed !important;
      // z-index: 100;
      top: 16.64rem;
    }
  }
  &.with-dynamic-multiSubInfo-search {
    top: 154px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 245px;
    }
  }
}

.fixed-search-header2 {
  position: fixed !important;
  width: 100%;
  z-index: 50;
  top: 65px;
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    top: 155px;
  }
  &.with-dynamic {
    top: 106px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 194px;
    }
  }
  &.with-month-salary {
    top: 112px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 207px;
    }
  }
  &.with-dynamic-header {
    top: 96px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 190px;
    }
  }
  &.with-dynamic-header {
    top: 96px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 190px;
    }
  }
  &.with-dynamic-multiInfo {
    top: 123px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 213px;
    }
  }
  &.with-dynamic-multiSubInfo {
    top: 138px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 228px;
    }
  }
  &.search-header-margin {
    top: 124px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 225px;
    }
  }
  &.with-tabs {
    top: 115px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 202px;
    }
  }
  &.with-company-title-search {
    top: 97px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 190px;
    }
  }
  &.with-disable-scroll-lock {
    overflow: hidden;
  }
  &.with-tabs-date {
    top: 114px;
    right: 4px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      right: 24px;
      // position: fixed !important;
      // z-index: 100;
      top: 202px;
    }
  }
  &.with-dynamic-tabs-search {
    top: 158px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 238px;
    }
  }
  &.with-tabs-search {
    top: 152px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 240px;
    }
  }
  &.bulletin-search {
    top: 148px;

    @media screen and (min-width: 600px) {
      width: calc(100% - 298px);
      // position: fixed !important;
      // z-index: 100;
      top: 236px;
    }
  }
  &.with-title-dynamic {
    top: 90px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 184px;
    }
  }
  &.with-title-name-position {
    top: 120px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 214px;
    }
  }
  &.with-dynamic-search {
    top: 128px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 218px;
    }
  }
  &.with-dynamic-year-tabs-search {
    top: 178px;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      // position: fixed !important;
      // z-index: 100;
      top: 265px;
    }
  }

  &.with-dynamic-year-tabs {
    top: 176px;
    z-index: 60;
    @media screen and (min-width: 600px) {
      width: calc(100% - 288px);
      height: 2.5rem;
      align-content: center;
      // position: fixed !important;
      // z-index: 100;
      top: 16.64rem;
    }
  }
}

.full-width {
  left: 24px;
  width: calc(100% - 48px);
}

.full-width {
  left: 24px;
  width: calc(100% - 48px);
}

.search-header-flex {
  display: flex;

  .search-header-booking {
    width: 100%;
  }
}

.search-header-dropdown {
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  &.MuiList-root {
    padding-bottom: 0px;
    width: 100%;
  }
  .MuiListItemIcon-root {
    min-width: 36px;
  }
  .MuiListItem-root {
    padding-top: 11px;
  }
  .MuiListItemText-root {
    margin: 0px;
    display: flex;
    padding: 4px 0px;
  }
  .MuiIconButton-edgeEnd {
    padding: 8px;
    color: #212121;
    margin-right: -8px;
  }
  .MuiInputBase-root {
    width: 100%;

    .MuiInputBase-input {
      padding: 0px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.with-tabs-top {
  top: 115px;
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    top: 202px;
  }
}

.with-mobile-top {
  top: 130px;
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    top: 215px;
  }
}

.with-dynamic-subheader {
  top: 100px;
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    top: 193px;
  }
}

.with-dynamic-subheader-full {
  top: 130px;
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    top: 222px;
  }
}

.with-dynamic-subheader-infoLine {
  top: 117px;
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    // position: fixed !important;
    // z-index: 100;
    top: 208px;
  }
}

.with-dynamic-daterange-search {
  top: 232px;
  @media screen and (min-width: 600px) {
    width: calc(100% - 288px);
    position: fixed !important;
    z-index: 100;
    top: 320px;
  }
}

.quill-content {
  img {
    max-width: 100%;
    height: auto;
  }

  p {
    margin: 0;
  }
}

.basic-header {
  padding: 0 16px 8px;
  @media screen and (min-width: 600px) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.form-content {
  padding: 0;
  @media screen and (min-width: 600px) {
    padding: 12px;
  }
}

.quill-error {
  .ql-container {
    border: 2px solid #e53935 !important;
  }
}

.color-picker {
  input[type='color' i] {
    border-radius: 16px !important;
    outline: none !important;
    border: none;
    background: none;
    margin: 0px !important;
    margin-right: 10px !important;
    padding: 0px !important;
    inline-size: 28px !important;
    block-size: 28px !important;
    cursor: pointer !important;
  }
}
